<template>
  <div class="flix-bg-danger flix-text-danger">
 <div class="flix-container" style="padding-top: 20px; padding-bottom: 20px;">
   Lieber Nutzer von Bookingflix.
   <br><br>

Am Montag, 9.12.2024, wird unser Terminservice eingestellt.
Bestehende kostenpflichtige Abos/Lastschriftmandate werden von uns bereits zum Ende des aktuellen Monats gekündigt.
<br><br>
Wir bitten Sie daher, sich frühzeitig um eine Alternative für Ihre Terminierung zu kümmern.
Bitte speichern Sie auch ggf. aktuelle Termindaten ab, da ab dem 10.12.2024 kein Zugriff mehr auf diese Daten möglich sein wird.
Das Dashboard von Bookingflix wird dann ebenfalls NICHT mehr erreichbar sein.
<br><br>
Wir danken Ihnen für die Nutzung unseres Services und wünschen alles Gute :-)
<br><br><br>

Mit freundlichen Grüßen<br>
Team Bookingflix
 </div>
 </div>
</template>
<script>
export default {
  name: 'endofTimeComponent'
}
</script>
<style lang="sass" scoped>
</style>
